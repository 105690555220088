import "react-toastify/dist/ReactToastify.css";
import Login from "./login.jsx";
import HomePage from "./HomePage";
import { useEffect, useState } from "react";
import { auth } from "./firebase";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";
import './css/custom.css';

function App(props) {
  const theme = createTheme({
    palette: { mode: "dark" },
    typography: {
      fontFamily: [
        'Montserrat',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  });

  const [user, setUser] = useState(localStorage.getItem("tkn"));

  useEffect(() => {
    if (user) {
      localStorage.setItem("tkn",user);
    }
  }, [user]);

  // useEffect(() => {
  //   return auth.onAuthStateChanged((user) => {
  //     setUser(user);
  //     user.getIdToken().then(function(idToken) {
  //       console.log(idToken);
  //       localStorage.setItem("tkn",idToken);
  //     });
  //   });
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer
        position="bottom-left"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover
      />
      {
        !user ?
          <Login
            setUser={setUser}
          />
          :
          <HomePage 
            setUser={setUser}          
          />
      }
    </ThemeProvider>
  );
}

export default App;
