import {Alert, Box, Button, Container, TextField, Typography} from "@mui/material";
import {useState, useEffect} from "react";
import {auth} from "./firebase";
import { Config } from "./Config.js";
import {
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';

export default function Login(props) {

  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [checkMail, setCheckMail] = useState(false);
  const [initializing, setInitializing] = useState(false);

  useEffect(() => {
    // Confirm the link is a sign-in with email link.
    if (isSignInWithEmailLink(auth, window.location.href)) {
      setInitializing(true);
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          setInitializing(false);
        })
        .catch((error) => {
          setError(error.error_description || error.message);
          setInitializing(false);
          console.error(error);
        });
    }
    
  }, []);

  if (initializing) return null;

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setError("Please enter your email.");
      return;
    }

    // clear the errors
    setError("");

    try {

      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: Config.RedirectedURLAfterAuthenticating,
        // This must be true.
        handleCodeInApp: true,
      };
      await sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem("emailForSignIn", email);
        })
        .catch((error) => {
          console.error(error);
        });
      setCheckMail(true);

    } catch (error) {
      console.error(error);
      setError(error.error_description || error.message);
    } finally {
    }
  };

  const responseMessage = (response) => {
    props.setUser(response.credential);
  };
  const errorMessage = (error) => {
      console.log(error);
  };

  return (
    <Container maxWidth="xs" sx={{mt: 2, textAlign: "center"}}>
      <Typography variant="h5" component="h1" gutterBottom textAlign="center">
        Sign in
      </Typography>
      <Typography variant="h6" textAlign="center">
        You must first to use this application.
      </Typography>
      <GoogleLogin onSuccess={responseMessage} onError={errorMessage} useOneTap={true} />
      {/* {error && <Alert severity="error" sx={{my: 2}}>{error}</Alert>}
      {!checkMail ?
        <Box component="form" onSubmit={onSubmit}>
          <TextField
            label="Email"
            variant="outlined"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{mt: 1}}
            fullWidth
          />
          <Button variant="contained" type="submit" sx={{mt: 3}} fullWidth>Sign in</Button>
        </Box>
      :
        <Alert severity="success" sx={{my: 2}}>Check your email for the login link!</Alert>
      } */}
    </Container>
  )
}