import { Box, Typography } from "@mui/material";
import { useState } from "react";
import logo from './img/logo.png';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import {auth} from "./firebase";
import { googleLogout } from '@react-oauth/google';


const Header = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    // auth.signOut();
    googleLogout();
    localStorage.removeItem("tkn");
    props.setUser(null);
  };

  return (
    <Box
      className="darkest-keepler"
      sx={{
        zIndex: 2,
        width: "100%",
        height: "80px",
        display: "flex",
        padding: "1.5rem",
      }}>
      <Box sx={{
        position: "relative",
        paddingX: 2,
        width: "100%"
      }}>
        <img src={logo} height="35"
          style={{
            float: "left",
            marginRight: "20px"
          }} />
        <Typography
          variant="h6"
          fontWeight="700"
          sx={{ float: "left" }}
        >
          Proposals GenAI
        </Typography>
      </Box>

      <Button
        id="basic-button"
        sx={{
          color: "#FFF",
        }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <SettingsIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleLogout}>Log out</MenuItem>
      </Menu>

    </Box>
  );
};

export default Header;