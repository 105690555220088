import axiosClient from "./axios.client.js";

export const chatCompletion = async (prompt) => {
  try {

    const response = await axiosClient.post("", prompt);

    console.log(response)

    return { response };
  } catch (err) {
    return { err };
  }
};

export const getStatusRequest = async (id) => {
  try {

    const response = await axiosClient.get(id);

    console.log(response)

    return { response };
  } catch (err) {
    console.log("error: "+err);
    return { err };
  }
};