import { initializeApp } from "firebase/app";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, getAuth, sendEmailVerification } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAovZYsfNOB_kmUmyQ2Gk8zzo9H_YI7bBA",
  authDomain: "k-es-solutions.firebaseapp.com",
  projectId: "k-es-solutions",
  storageBucket: "k-es-solutions.appspot.com",
  messagingSenderId: "219745350227",
  appId: "1:219745350227:web:d86b82dc90e39b89d20ca6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const createUser = async (email, password) => {
  const userCredential = await createUserWithEmailAndPassword(getAuth(app), email, password);
  await sendEmailVerification(userCredential.user);
  getAuth(app).signOut();
  return userCredential;
}

export const signInUser = async (email, password) => {
  return signInWithEmailAndPassword(getAuth(app), email, password);
}

export const storage = getStorage(app);