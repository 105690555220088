import axios from "axios";
import queryString from "query-string";
import { Config } from "../Config.js";

const baseURL = Config.BackendEndpointURL;

const axiosClient = axios.create({
  baseURL,
});

axiosClient.interceptors.request.use(async config => {

  config.params = {
    key: "AIzaSyB_Z0WN7_bxhlrUylqs9REbcrzW3bXptyc",
    ...config.params,
  };
  config.headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("tkn")}`,
  }
  return config;

});

axiosClient.interceptors.response.use((response) => {
  if (response && response.data) return response.data;
  return response;
}, error => {
  throw error;
});

export default axiosClient;