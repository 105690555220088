import Header from "./Header.jsx";
import FileUpload from "./FileUpload.jsx";
import { chatCompletion } from "./api/chat.api";
import { getStatusRequest } from "./api/chat.api";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { Stack, Box, Typography, FormControl, OutlinedInput, CircularProgress, Grid, TextField, Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const messageType = {
  answer: "answer",
  question: "question"
};

const CloudType = {
  AWS: "Amazon Web Services",
  GCP: "Microsoft Azure",
  AZURE: "Google Cloud Platform",
};

const LanguageType = {
  ES: "Spanish",
  EN: "English",
};

const defaultProposal = "No proposals have been generated for now";
const defaultQuestion = {
  "customer_name": "", 
  "project_overview": "",
  "project_goals": "",
  "specific_requirements": "",
  "cloud": CloudType.AWS,
  "language": LanguageType.ES,
};


const HomePage = (props) => {
  const inputRef = useRef();
  const chatWrapperRef = useRef();

  const [onRequest, setOnRequest] = useState(false);
  const [question, setQuestion] = useState(defaultQuestion);
  const [messages, setMessages] = useState(defaultProposal);

  const isFormValid = (question) => {
    return  question.customer_name != "" &&
            question.project_overview != "" &&
            question.project_goals != "" &&
            question.specific_requirements != "" &&
            question.cloud != "" &&
            question.language != "";
  }

  const getAnswer = async () => {
    if (onRequest) return;

    console.log(question);

    setOnRequest(true);
    setMessages("Waiting for the proposal. This may take 1 or 2 minutes...")

    const { response, err } = await chatCompletion(question);

    if (response && response.id){
      let idRequest = response.id;

      const doGETRequest = async () => {
        try {
          const { response, err } = await getStatusRequest(idRequest);
          if (response.status != 'finished'){
            return setTimeout(doGETRequest, 5000);
          }else{
            setMessages(response.proposal);
            setOnRequest(false);
          }
        } catch {
          toast.error("Server error");
          setOnRequest(false);
          setMessages(defaultProposal);
        }
      }

      await doGETRequest();
    }
    

    if (err) {
      console.log(err);

      if (err && err.code == "ERR_NETWORK"){
        localStorage.removeItem("tkn");
        props.setUser(null);
        toast.error("Expired Session");
      } else {
        toast.error(err.message);
      }

      toast.error(err.message);
      setOnRequest(false);
      setMessages(defaultProposal);
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13) getAnswer();
  };


  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      sx={{ height: "100%" }}
    >
      <Header setUser={props.setUser}/>
      <div className="darker-keepler" ref={chatWrapperRef}
        style={{
          height: "100%",
          position: "fixed",
          zIndex: 1,
          width: "100%",
          overflowY: "auto",
          paddingTop: "90px",
          paddingBottom: "90px"
        }}>

        <Box className="action-block-keepler" >
          {/* <FileUpload></FileUpload> */}

          <LoadingButton
            onClick={getAnswer}
            loading={onRequest}
            loadingPosition="end"
            endIcon={<SendIcon />}
            variant="contained"
            disabled={!isFormValid(question)}
          >
            <span>Generate proposal</span>
          </LoadingButton>
        </Box>

        <Grid container spacing={2} className="main-content-keepler" >
          <Grid item xs={6} >
            <Box className="main-block-keepler notes" >
              <Grid container spacing={2}>
                <Grid item xs={4} >
                  <Typography
                    variant="h7"
                    fontWeight="700"
                  >
                    Customer name
                  </Typography>
                  <TextField
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none"
                      }
                    }}
                    placeholder="E.g. Company S.L."
                    multiline
                    fullWidth 
                    onChange={(e) => setQuestion({...question, "customer_name":e.target.value})}
                  />
                </Grid>
                <Grid item xs={4} >
                  <Typography
                    variant="h7"
                    fontWeight="700"
                  >
                    Cloud
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none"
                      }
                    }}
                      defaultValue={CloudType.AWS}
                      id="demo-simple-select"
                      // value={age}
                      // label="Age"
                      onChange={(e) => setQuestion({...question, "cloud":e.target.value})}
                    >
                      <MenuItem value={CloudType.AWS}>{CloudType.AWS}</MenuItem>
                      <MenuItem value={CloudType.GCP}>{CloudType.GCP}</MenuItem>
                      <MenuItem value={CloudType.AZURE}>{CloudType.AZURE}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} >
                  <Typography
                    variant="h7"
                    fontWeight="700"
                  >
                    Language
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none"
                      }
                    }}
                      defaultValue={LanguageType.ES}
                      id="demo-simple-select"
                      onChange={(e) => setQuestion({...question, "language":e.target.value})}
                    >
                      <MenuItem value={LanguageType.ES}>{LanguageType.ES}</MenuItem>
                      <MenuItem value={LanguageType.EN}>{LanguageType.EN}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Typography
                variant="h7"
                fontWeight="700"
              >
                Project Overview
              </Typography>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none"
                  }
                }}
                placeholder="Enter your description (limit: 4000 characters)"
                multiline
                fullWidth 
                inputProps={{ maxLength: 4000 }}
                onChange={(e) => setQuestion({...question, "project_overview":e.target.value})}
              />
              <Typography
                variant="h7"
                fontWeight="700"
              >
                Project Goals
              </Typography>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none"
                  }
                }}
                placeholder="Enter your description (limit: 4000 characters)"
                multiline
                fullWidth 
                inputProps={{ maxLength: 4000 }}
                onChange={(e) => setQuestion({...question, "project_goals":e.target.value})}
              />
              <Typography
                variant="h7"
                fontWeight="700"
              >
                Specific Requirements
              </Typography>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none"
                  }
                }}
                placeholder="Enter your description (limit: 4000 characters)"
                multiline
                fullWidth 
                inputProps={{ maxLength: 4000 }}
                onChange={(e) => setQuestion({...question, "specific_requirements":e.target.value})}
              />
            </Box>
            
          </Grid>
          <Grid item xs={6}>
            <Box className="main-block-keepler dark-keepler" 
            >

              <Typography
                variant="h7"
                fontWeight="700"
              >
                Generated proposal
              </Typography>

              <Typography className="generated-content"
              >
                {messages}
              </Typography>
            
            </Box>
          </Grid>
        </Grid>
      </div>
    </Stack >
  );
};

export default HomePage;